<template>
  <footer class="footer__section footer__bg ">
    <div class="main__footer style2 border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 footer-col-md-order" >
            <div class="footer__widget">
              <div class="footer__widget--title d-none d-md-2-block">About Us <button class="footer__widget--button" aria-label="footer widget button" @click="openClick('AboutUs')"></button>
                <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                  <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                </svg>
              </div>
              <div class="footer__widget--inner"  ref="AboutUs" id="AboutUs">
                <a class="footer__logo" href="/">
                  <img class="footer__logo--img logo__light" src="/images/logo-dark.png">
                </a>
                <p class="footer__widget--desc">IncCrypto to expand its cloud mining business to the whole crypto industry chain in the future and serve global miners with the latest blockchain technology innovations.</p>
<!--                <div class="footer__social">-->
<!--                  <ul class="social__shear background-color-3 d-flex">-->
<!--                    <li class="social__shear&#45;&#45;list">-->
<!--                      <a class="social__shear&#45;&#45;list__icon" href="https://twitter.com/StavlinShopUK" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-original-title="Twitter">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.492" viewBox="0 0 24 19.492">-->
<!--                          <path data-name="Icon awesome-twitter" d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.176,10.176,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076,5.2,5.2,0,0,0,1.294-.167A4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.167,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z" transform="translate(0 -2.254)" fill="currentColor"></path>-->
<!--                        </svg>-->
<!--                        <span class="visually-hidden">Twitter</span>-->
<!--                      </a>-->
<!--                    </li>-->
<!--                    <li class="social__shear&#45;&#45;list">-->
<!--                      <a class="social__shear&#45;&#45;list__icon" href="https://www.facebook.com/StavlinShopUK/" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-original-title="Facebook">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="8.239" height="15.984" viewBox="0 0 11.239 20.984">-->
<!--                          <path data-name="Icon awesome-facebook-f" d="M11.575,11.8l.583-3.8H8.514V5.542A1.9,1.9,0,0,1,10.655,3.49h1.657V.257A20.2,20.2,0,0,0,9.371,0c-3,0-4.962,1.819-4.962,5.112V8.006H1.073v3.8H4.409v9.181H8.514V11.8Z" transform="translate(-1.073)" fill="currentColor"></path>-->
<!--                        </svg>-->
<!--                        <span class="visually-hidden">Facebook</span>-->
<!--                      </a>-->
<!--                    </li>-->
<!--                    <li class="social__shear&#45;&#45;list">-->
<!--                      <a class="social__shear&#45;&#45;list__icon" href="https://www.youtube.com/channel/UCZqNy7_cyCoGfgvC3EPZ_QQ" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-original-title="Youtube">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="15.49" height="15.582" viewBox="0 0 16.49 11.582">-->
<!--                          <path data-name="Path 321" d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z" transform="translate(-951.269 -1359.8)" fill="currentColor"></path>-->
<!--                        </svg>-->
<!--                        <span class="visually-hidden">Youtube</span>-->
<!--                      </a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer__widget">
              <div class="footer__widget--title ">Learn <button class="footer__widget--button" aria-label="footer widget button"></button>
                <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                  <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                </svg>
              </div>
              <ul class="footer__widget--menu footer__widget--inner" ref="Learn">
<!--                <li class="footer__widget&#45;&#45;menu__list"><a class="footer__widget&#45;&#45;menu__text" href="/avoidscam">Avoid scams</a></li>-->
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/what-is-bitcoin">What is Bitcoin?</a></li>
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/what-is-mining">What is Mining?</a></li>
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/how-it-work">How it works?</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer__widget">
              <div class="footer__widget--title ">Resources <button class="footer__widget--button" aria-label="footer widget button"></button>
                <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                  <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                </svg>
              </div>
              <ul class="footer__widget--menu footer__widget--inner" ref="Resources">
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/referral">Referral</a></li>
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/faq">FAQ</a></li>
<!--                <li class="footer__widget&#45;&#45;menu__list"><a class="footer__widget&#45;&#45;menu__text" href="/blog">Blog</a></li>-->
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/reviews">Reviews</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer__widget">
              <div class="footer__widget--title ">Company <button class="footer__widget--button" aria-label="footer widget button"></button>
                <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                  <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                </svg>
              </div>
              <ul class="footer__widget--menu footer__widget--inner" ref="Company">
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/about-us">About Us</a></li>
<!--                <li class="footer__widget&#45;&#45;menu__list"><a class="footer__widget&#45;&#45;menu__text" href="/guidelines">Guidelines</a></li>-->
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/terms">Terms of Service</a></li>
                <li class="footer__widget--menu__list"><a class="footer__widget--menu__text" href="/policy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 footer-col-md-order">
            <div class="footer__widget">
              <div class="footer__widget--title ">Contact Us
                <button class="footer__widget--button" aria-label="footer widget button"></button>
                <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                  <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                </svg>
              </div>
              <ul class="footer__widget--menu footer__widget--inner" ref="Company">
                <li class="footer__widget--menu__list">
                  <map-pin-icon class="fea icon-sm icons"></map-pin-icon>
<!--                  <i class="fa fa-map-marker  fa-lg" aria-hidden="true"></i>-->
                  &nbsp;Unit 5 Hadrians Way, Rugby, <br/>United Kingdom, CV21 1ST
                </li>
                <li class="footer__widget--menu__list">
                  <mail-icon class="fea icon-sm icons"></mail-icon>
<!--                  <i class="fa fa-envelope" aria-hidden="true"></i>-->
                  &nbsp;info@inccrypto.com
                </li>
                <li class="footer__widget--menu__list">
                  <img src="../assets/img/other/icon_comodo.png">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom ">
      <div class="container">
        <div class="footer__bottom--inner d-flex justify-content-between align-items-center" style="justify-content: center !important;">
<!--          <a href="https://find-and-update.company-information.service.gov.uk/company/13811148" target="_blank" >-->
<!--          <a href="https://find-and-update.company-information.service.gov.uk/company/13573315" target="_blank" >-->
<!--            <img src="images/app/about.png" width="70px;">-->
<!--          </a>-->
          <p class="copyright__content  m-0">Copyright © 2021-2022 <a class="copyright__content--link" href="/">INCCRYPTO</a> ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </div>




  </footer>
</template>

<script>
import {MapPinIcon, MailIcon} from "vue-feather-icons";
export default {
  name: "FooterNew",
  components:{
    MapPinIcon,
    MailIcon
  },
  data(){
    return {
      isOpenAboutUs: false,
      aboutUsHeight: 0,
      heightObj: {
        AboutUs: 0
      },
      accordion: true
    }
  },
  mounted() {
    const that = this;
    this.footerWidgetAccordion()
    window.addEventListener("resize", function () {
      document.querySelectorAll(".footer__widget").forEach(function (item) {
        if (window.outerWidth >= 768) {
          item.classList.remove("active");
          item.querySelector(".footer__widget--inner").style.display = "";
        }
      });
      if (that.accordion) {
        that.footerWidgetAccordion();
      }
    })
  },
  destroyed() {
    window.removeEventListener('resize', function () {

    })
  },
  methods:{
    footerWidgetAccordion(){
      const that = this;
      that.accordion = false;
      let footerWidgetContainer = document.querySelector(".main__footer");
      console.log(footerWidgetContainer)
      footerWidgetContainer.addEventListener("click", function (evt) {
        let singleItemTarget = evt.target;
        if (singleItemTarget.classList.contains("footer__widget--button")) {
          const footerWidget = singleItemTarget.closest(".footer__widget"),
              footerWidgetInner = footerWidget.querySelector(".footer__widget--inner");
          if (footerWidget.classList.contains("active")) {
            footerWidget.classList.remove("active");
            that.slideUp(footerWidgetInner);
          } else {
            footerWidget.classList.add("active");
            that.slideDown(footerWidgetInner);
            that.getSiblings(footerWidget).forEach(function (item) {
              const footerWidgetInner = item.querySelector(".footer__widget--inner");
              item.classList.remove("active");
              that.slideUp(footerWidgetInner);
            });
          }
        }
      });
    },
    openClick(name){
      // console.log(this.$refs[name].clientHeight)
      name = 'isOpen' + name;
      // console.log(name)
      if(this.$refs[name]){
        this.$refs[name].style.display = "none";
      }

    },
    getSiblings (elem) {
      const siblings = [];
      let sibling = elem.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    },
    slideUp (target, time) {
      const duration = time ? time : 500;
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.boxSizing = "border-box";
      target.style.height = target.offsetHeight + "px";
      target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      window.setTimeout(function () {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
    slideDown (target, time) {
      const duration = time ? time : 500;
      target.style.removeProperty("display");
      let display = window.getComputedStyle(target).display;
      if (display === "none") display = "block";
      target.style.display = display;
      const height = target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      target.offsetHeight;
      target.style.boxSizing = "border-box";
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.height = height + "px";
      window.setTimeout(function () {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
  },

}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';
.footer__section{
  font-size: 0.9375rem;
  line-height: 1.625rem;

  .footer__bg {
    background: #f9f9f9
  }

  .main__footer {
    padding: 3.4375rem 0 2.1875rem
  }

  @media only screen and (min-width: 768px) {
    .main__footer {
      padding: 3.75rem 0 1.875rem
    }
  }

  @media only screen and (min-width: 992px) {
    .main__footer {
      padding: 4.375rem 0
    }
  }

  @media only screen and (min-width: 1200px) {
    .main__footer {
      padding: 5rem 0
    }
  }

  .main__footer.style2 {
    padding: 6.5625rem 0 2.1875rem
  }

  @media only screen and (min-width: 768px) {
    .main__footer.style2 {
      padding: 7.0625rem 0 1.875rem
    }
  }

  @media only screen and (min-width: 992px) {
    .main__footer.style2 {
      padding: 7.5rem 0 4.375rem
    }
  }

  @media only screen and (min-width: 1200px) {
    .main__footer.style2 {
      padding: 8.125rem 0 5rem
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-col-md-order {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1
    }
  }

  .footer__social {
    margin-top: 0.9375rem
  }

  @media only screen and (min-width: 992px) {
    .footer__social {
      margin-top: 1.25rem
    }
  }

  .social__shear--list {
    margin-right: .5rem
  }

  .social__shear--list:last-child {
    margin-right: 0
  }

  @media only screen and (min-width: 575px) {
    .social__shear--list {
      margin-right: .625rem
    }
  }

  .social__shear--list__icon {
    width: 2rem;
    height: 2rem;
    color: #fff;
    background: #343444;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%
  }

  .social__shear--list__icon:hover {
    color: #fff;
    background: #0d6efd
  }

  .footer__logo {
    padding: 0 5%;
    margin-bottom: 0.625rem;
    display: block;
  }

  @media only screen and (max-width: 767px) {
    .footer__logo--img {
      max-width: 140px
    }
  }

  @media only screen and (max-width: 479px) {
    .footer__logo--img {
      max-width: 125px
    }
  }

  @media only screen and (max-width: 991px) {
    .footer__widget {
      margin-bottom: 1.875rem
    }
  }

  @media only screen and (max-width: 767px) {
    .footer__widget {
      margin-bottom: 0
    }
  }

  .footer__widget--desc {
    font-size: 0.875rem;
    margin-bottom: 0
  }

  @media only screen and (min-width: 768px) {
    .footer__widget--desc {
      font-size: 0.9375rem
    }
  }

  .footer__widget--title {
    margin-bottom: 1.25rem;
    font-weight: 600;
    position: relative;
    font-size: 1.0625rem;
    line-height: 1.375rem
  }

  @media only screen and (min-width: 768px) {
    .footer__widget--title {
      font-size: 1.25rem;
      line-height: 1.5rem
    }
  }

  @media only screen and (min-width: 768px) {
    .footer__widget--title {
      margin-bottom: 0.9375rem
    }
  }

  @media only screen and (min-width: 992px) {
    .footer__widget--title {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin-bottom: 1.25rem
    }
  }

  .footer__widget--title__arrowdown--icon {
    position: absolute;
    right: 0;
    top: 38%;
    -webkit-transition: .3s;
    transition: .3s;
    display: none
  }

  @media only screen and (max-width: 767px) {
    .footer__widget--title__arrowdown--icon {
      display: block
    }
  }

  .footer__widget.active {
    padding-bottom: 1.875rem
  }

  .footer__widget.active .footer__widget--title__arrowdown--icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .footer__widget--menu__list {
    margin-bottom: 0.375rem;
  }
    @media only screen and (min-width: 768px) {
      .footer__widget--menu__list {
        margin-bottom: .5rem
      }
    }

    .footer__widget--menu__list:last-child {
      margin-bottom: 0
    }

    .footer__widget--menu__text {
      line-height: 1.625rem;
      font-size: 0.875rem
    }

    @media only screen and (min-width: 768px) {
      .footer__widget--menu__text {
        line-height: 1.75rem;
        font-size: 0.9375rem
      }
    }

    .footer__widget--button {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      content: "";
      color: transparent;
      border: 0;
      background-color: transparent
    }

    @media only screen and (max-width: 767px) {
      .footer__widget--button {
        visibility: visible
      }
    }

    @media only screen and (max-width: 767px) {
      .footer__widget--inner {
        display: none;
        //transition: all .5s ease;
        //&.active {
        //  box-sizing: border-box;
        //  display: block;
        //}
      }
    }

    .footer__bottom {
      padding: 1.5625rem 0
    }

    @media only screen and (min-width: 768px) {
      .footer__bottom {
        padding: 1.5625rem 0
      }
    }

    @media only screen and (max-width: 767px) {
      .footer__bottom--inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center
      }
    }

    .footer__bottom--desc {
      color: rgb(0, 0, 0);
    }

    .copyright__content {
      color: rgb(0, 0, 0);
    }

    @media only screen and (max-width: 767px) {
      .copyright__content.m-0 {
        margin-bottom: .625rem !important
      }
    }

    .copyright__content--link:hover {
      color: #6610f2;
      text-decoration: underline
    }

    .footer__recent--post__items {
      gap: 0.9375rem
    }

    .footer__recent--post__items:hover .footer__post--thumbnail__link img {
      -webkit-transform: scale(1.03);
      transform: scale(1.03)
    }

    .footer__recent--post__thumbnail {
      width: 3.9375rem
    }

    .footer__recent--post__contant {
      width: calc(100% - 3.9375rem)
    }

    .footer__recent--post__date {
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin-bottom: 0.4375rem;
      color: #646464
    }

    .footer__recent--post__title {
      font-size: 0.875rem;
      line-height: 1.5rem
    }

    .footer__post--thumbnail__link {
      overflow: hidden
    }

    @media only screen and (min-width: 992px) {
      .footer__bottom.style3 {
        padding: 2.1875rem 0
      }
    }

    .footer__bottom--inner.style3 {
      gap: 1.5625rem
    }

    @media only screen and (max-width: 1199px) {
      .footer__bottom--inner.style3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
      }
    }

    .footer__info--app {
      gap: 1.25rem
    }

    @media only screen and (max-width: 575px) {
      .footer__info--app {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
      }
    }

    .footer__info--app__desc a {
      color: #0d6efd
    }

    .footer__info--app__desc a:hover {
      text-decoration: underline
    }

    .footer__contact--info {
      gap: 0.9375rem;
      border-right: 1px solid #e4e4e4;;
      border-left: 1px solid #e4e4e4;;
      padding: 0 1.875rem
    }

    @media only screen and (min-width: 1366px) {
      .footer__contact--info {
        padding: 0 3.4375rem
      }
    }

    @media only screen and (max-width: 767px) {
      .footer__contact--info {
        border: 0;
        padding: 0
      }
    }

    .footer__contact--info__text {
      line-height: 2rem
    }
}
@media only screen and (max-width: 991px) {
  .footer__section{
    font-size:0.875rem;
    line-height: 1.5rem;
  }
}



</style>
